import React, { useState, useEffect } from "react";
import one from '../../Assets/1.jpg'
import two from '../../Assets/2.jpg'
import three from '../../Assets/3.jpg'
import four from '../../Assets/4.jpg'
import five from '../../Assets/5.jpg'
import six from '../../Assets/6.jpg'
import seven from '../../Assets/7.jpg'

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    {
      id: 1,
      content: (
        <div className="bg-gray-800 w-full flex items-center justify-center text-white">
          <img
            src={one}
            alt=""
            className="w-full lg:h-[600px] lg:object-cover"
          />
        </div>
      ),
    },
    {
      id: 2,
      content: (
        <div className="bg-gray-800 w-full flex items-center justify-center text-white">
          <img
            src={two}
            alt=""
            className="w-full lg:h-[600px] lg:object-cover"
          />
        </div>
      ),
    },
    {
      id: 3,
      content: (
        <div className="bg-gray-800 w-full flex items-center justify-center text-white">
          <img
            src={three}
            alt=""
            className="w-full lg:h-[600px] lg:object-cover"
          />
        </div>
      ),
    },
    {
      id: 4,
      content: (
        <div className="bg-gray-800 w-full flex items-center justify-center text-white">
          <img
            src={four}
            alt=""
            className="w-full lg:h-[600px] lg:object-cover"
          />
        </div>
      ),
    },
    {
      id: 5,
      content: (
        <div className="bg-gray-800 w-full flex items-center justify-center text-white">
          <img
            src={five}
            alt=""
            className="w-full lg:h-[600px] lg:object-cover"
          />
        </div>
      ),
    },
    {
      id: 6,
      content: (
        <div className="bg-gray-800 w-full flex items-center justify-center text-white">
          <img
            src={six}
            alt=""
            className="w-full lg:h-[600px] lg:object-cover"
          />
        </div>
      ),
    },
    {
      id: 7,
      content: (
        <div className="bg-gray-800 w-full flex items-center justify-center text-white">
          <img
            src={seven}
            alt=""
            className="w-full lg:h-[600px] lg:object-cover"
          />
        </div>
      ),
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  // Autoplay using useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // 10 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [currentIndex]);

  return (
    <div className="relative w-full mx-auto mb-5">
      {/* Slides */}
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-300"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((slide) => (
            <div key={slide.id} className="min-w-full">
              {slide.content}
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full"
      >
        ❮
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full"
      >
        ❯
      </button>

      {/* Dots */}
      <div className="flex justify-center space-x-2 mt-4">
        {slides.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              index === currentIndex ? "bg-gray-800" : "bg-gray-400"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
