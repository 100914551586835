import React from "react";

const LocationPage = () => {
    let mapLink = 'https://maps.app.goo.gl/cBa8oMoDHMdYtpHp9'
  return (
    <div className="flex flex-col items-center justify-center  bg-gray-100 p-6 mb-10">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Our Location</h1>
      <p className="text-gray-600 text-center mb-6">
        Click the button below to view our office location on Google Maps.
      </p>
      <a
        href={mapLink}
        target="_blank"
        rel="noopener noreferrer"
        className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition"
      >
        Open in Google Maps
      </a>
    </div>
  );
};

export default LocationPage;
