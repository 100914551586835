import React from "react";
import Moazzam from "../../../../myapp/src/Assets/moazzam.jpg";

const CEOSection = () => {
  const handleProfileClick = () => {
    window.location.href = "/ceo-profile"; // Update this URL to the CEO's profile page
  };

  return (
    <>
      <section className="p-6 bg-gray-100">
        <div className="max-w-screen-xl mx-auto text-center" id="team">
          <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-8">
            Meet Our CEO
          </h2>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* CEO Image */}
          <div className="relative w-40 h-40 md:w-64 md:h-64 overflow-hidden group rounded-full shadow-lg transition-transform transform hover:scale-105 flex items-center justify-center">
            <img
              src={Moazzam} // Replace with the CEO's image URL
              alt="CEO"
              className="object-cover w-full h-full"
            />
            {/* Hover Effect */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <button
                onClick={handleProfileClick}
                className="px-6 py-3 bg-white text-black font-semibold rounded-lg shadow-lg hover:bg-gray-200 transition duration-300"
              >
                View Profile
              </button>
            </div>
          </div>

          {/* About Text */}
          <div className="mt-6 md:mt-0 md:ml-8 text-center md:text-left">
            <h2 className="text-2xl font-bold text-gray-800">
              Syed Moazzam Ali Shah
            </h2>
            <h3 className="text-md font-semibold text-gray-600">
              Advocate Supreme Court
            </h3>
            <p className="mt-2 text-gray-700 max-w-md mx-auto md:mx-0">
              Syed Moazzam Ali Shah is the visionary CEO of our company, with
              over 20 years of experience in leading innovative teams to
              success. His dedication to excellence has been the driving force
              behind our growth.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default CEOSection;
