import React from "react";

const About = () => {
  return (
    <section
      id="about"
      className="bg-gray-100 dark:bg-gray-900 py-16 px-4 md:px-8 lg:px-16"
    >
      <div className="max-w-screen-xl mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 dark:text-white mb-8">
          About Us
        </h2>
        <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 mb-12">
          Welcome to{" "}
          <span className="text-blue-600 dark:text-blue-400 font-semibold">
            Zia & Shah Legal Consultants
          </span>
          . We are a dedicated team of professionals committed to providing
          expert legal advice and guidance tailored to your needs.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Who We Are */}
          <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
              Who We Are
            </h3>
            <p className="text-gray-600 dark:text-gray-300 text-justify">
              Zia & Shah is a leading corporate and commercial law firm,
              dedicated to delivering top-tier legal services to businesses,
              organizations, and institutions both locally and internationally.
              Our practice encompasses advisory work, consultancy, litigation,
              and alternative dispute resolution, ensuring tailored solutions
              for diverse legal needs.
            </p>
          </div>

          {/* Where We Come From */}
          <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
              Where We Come From
            </h3>
            <p className="text-gray-600 dark:text-gray-300 text-justify">
              Founded in May 2010, Zia & Shah carries forward the legacy of
              Afridi, Shah & Minallah (ASM) Legal Consultants. With expertise in
              Corporate and Civil Litigation, commercial law, and white-collar
              crime, we remain committed to public interest litigation, legal
              education, and reform. Our mission is to contribute meaningfully
              to the socio-economic development of our nation, combining
              integrity, scholarship, and service. Our legacy inspires us. Our
              mission drives us.
            </p>
          </div>

          {/* Our Services */}
          <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
              Our Services
            </h3>
            <p className="text-gray-600 dark:text-gray-300 text-justify">
              The areas of focus within law and business include Banking and
              Finance, Competition/Anti-Monopoly, Capital Markets and
              Securities, Energy, Foreign Investment, and Information Technology
              and Media. Other important sectors are Tax, Insurance, Mergers &
              Acquisitions, Property/Real Estate, Corporate and Commercial, as
              well as Construction and Real Estate. Employment and Labour law
              also plays a key role, alongside concerns related to the
              Environment, International Trade, and Intellectual Property and
              E-Commerce. Litigation and Alternative Dispute Resolution are
              essential for resolving conflicts, while Oil & Gas regulations
              govern the energy sector. Public and Private Sector Projects are
              another vital area, with Family, Civil, and Criminal law
              addressing personal and legal issues across various contexts.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
