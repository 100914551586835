import React from "react";
import { Route, Routes } from "react-router-dom";
import Loginpage from "../../Screens/Portal/Auth/Loginpage";
import Homepage from "../../Screens/Website/Homepage";
import Navbar from "../Website/Navbar";
import AboutUs from "../../Screens/Website/Aboutus";
import Ceoprofile from "../../Screens/Website/Ceoprofile";

const Webrouting = () => {
  return (
    <>
      <>
        <Navbar />
        <Routes>
          {/* Website Routes */}
          <Route path="/" element={<Homepage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/login" element={<Loginpage />} />
          <Route path="/ceo-profile" element={<Ceoprofile/>} />
        </Routes>
      </>
    </>
  );
};

export default Webrouting;
