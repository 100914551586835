import React from "react";

const associates = [
  { name: "Rana Muhammad Akram", title: "Advocate High Court" },
  { name: "Khadija Amjad Wazir", title: "Advocate High Court" },
  { name: "Shahram Anwar", title: "Advocate High Court" },
  { name: "Syed Ahmed Hasnain Rizvi", title: "Advocate" },
  { name: "Rana Haseeb Ahmed", title: "Advocate" },
  { name: "Hina Naseeb", title: "Advocate" },
  { name: "Amna Hassan", title: "Advocate" },
  { name: "Sikandar Rehman Khan Niazi", title: "Advocate" },
  { name: "Ayesha Ali", title: "Advocate" },
  { name: "Fatima Ali", title: "Advocate" },
];

const AssociatesSection = () => {
  return (
    <section className="p-6 bg-gray-50">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
        Our Associates
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {associates.map((associate, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-4 text-center hover:shadow-xl transition-shadow duration-300"
          >
            <div className="w-20 h-20 mx-auto mb-4 bg-gray-200 rounded-full flex items-center justify-center">
              <span className="text-xl font-bold text-gray-600">
                {associate.name.charAt(0)}
              </span>
            </div>
            <h3 className="text-xl font-semibold text-gray-800">
              {associate.name}
            </h3>
            <p className="text-gray-600">{associate.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AssociatesSection;
