import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousal from "../../Components/Website/Carousal";
import About from "./Aboutus";
import Team from "./Team";
import AssociatesSection from "./Associates";
import CenteredNav from "../../Components/Website/Centernav";
import GoogleMap from "../../Components/Website/Googlemap";

const Homepage = () => {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    // Optional: Uncomment this if you need reload logic
    // window.location.reload();
  }, []);

  return (
    <>
      <nav className="bg-gray-100 border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-end mx-auto p-4">
          <button
            onClick={toggleMobileMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-dropdown"
            aria-expanded={isMobileMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`w-full md:block md:w-auto ${
              isMobileMenuOpen ? "block" : "hidden"
            }`}
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
              {/* Navigation Links */}
              <li>
                <a
                  href="#about"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("about")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#team"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("team")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Team
                </a>
              </li>
              {/* <li>
                <a
                  href="#expertise"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("expertise")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Our Expertise
                </a>
              </li> */}
              {/* <li>
                <a
                  href="#news"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("news ")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  News
                </a>
              </li> */}
              {/* <li>
                <a
                  href="#faqs"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("faqs")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  FAQs
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <div id="carousal">
        <Carousal />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="team">
        <Team />
      </div>
      <div id="associates">
        <AssociatesSection />
      </div>

      <div id="location">
        <GoogleMap/>
      </div>

      {/* Scroll to Top Button */}
      <button
        onClick={scrollToTop}
        className="fixed bottom-16 right-4  bg-gray-500 text-white w-10 h-10 rounded-full shadow-lg hover:bg-blue-600 transition"
        aria-label="Scroll to top"
      >
        ↑
      </button>
      <CenteredNav />
    </>
  );
};

export default Homepage;
