import React from 'react';

const Ceoprofile = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      <h1 className="text-4xl font-bold text-center mb-6">Curriculum Vitae</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Personal Details</h2>
        <ul className="list-disc pl-5">
          <li><strong>Name:</strong> Syed Moazzam Ali Shah</li>
          <li><strong>Date of Birth:</strong> 25 July 1968</li>
          <li><strong>Passport:</strong> Pakistan</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Education</h2>
        <ul className="list-disc pl-5">
          <li><strong>Masters of Business Administration:</strong> 1997, The International University Missouri</li>
          <li><strong>Bachelor of Laws:</strong> 1993, University of Punjab</li>
          <li><strong>Bachelor of Science:</strong> 1988, University of Punjab</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Membership of Professional Bodies</h2>
        <ul className="list-disc pl-5">
          <li>Licentiate, Punjab Bar Council</li>
          <li>Member Lahore High Court Bar Association</li>
          <li>Member Pakistan Bar Council (Supreme Court of Pakistan)</li>
          <li>Advocate Supreme Court of Pakistan, Supreme Court Bar Association Pakistan</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Professional Experience</h2>
        <p><strong>Overall Experience:</strong> 24 years</p>
        <table className="min-w-full table-auto border-collapse mt-4">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2 border">Period</th>
              <th className="px-4 py-2 border">Position</th>
              <th className="px-4 py-2 border">Nature of Work</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2 border">1995-2009</td>
              <td className="px-4 py-2 border">Senior Associate, Afridi Shah and Minallah</td>
              <td className="px-4 py-2 border">
                <ul className="list-disc pl-5">
                  <li>Court representation in criminal, civil and corporate matters</li>
                  <li>Provision of legal advice and opinion on criminal prosecutions, investigation, white collar crime, banking offences</li>
                  <li>Preparation and representation of public interest cases</li>
                  <li>Consulting: World Bank on project relating to Corruption in Lower Judiciary</li>
                  <li>Assessment of legal and regulatory framework for urban land development in Punjab</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border">2010-till date</td>
              <td className="px-4 py-2 border">Independent Practice (Zia and Shah)</td>
              <td className="px-4 py-2 border">
                <ul className="list-disc pl-5">
                  <li>Representation in Civil Courts, Banking Courts, Criminal Courts, etc.</li>
                  <li>Advice on Civil, Criminal, Environment, Service, Company, Land, Planning and Development matters</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Representation of Government Agencies and Departments</h2>
        <table className="min-w-full table-auto border-collapse mt-4">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2 border">Period</th>
              <th className="px-4 py-2 border">Agency Represented</th>
              <th className="px-4 py-2 border">Nature of Work</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2 border">2009-2010</td>
              <td className="px-4 py-2 border">Legal Advisor, Parks and Horticulture Authority</td>
              <td className="px-4 py-2 border">
                <ul className="list-disc pl-5">
                  <li>Handled more than 600 cases</li>
                  <li>Representation in High Court, Punjab Appellate Tribunal, Labour courts</li>
                  <li>Provision of advice</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border">2012-2013</td>
              <td className="px-4 py-2 border">Punjab Seed Corporation</td>
              <td className="px-4 py-2 border">
                <ul className="list-disc pl-5">
                  <li>Representation in High Court, Punjab Appellate Tribunal, Labour courts</li>
                  <li>Provision of advice</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Consulting Experience</h2>
        <ul className="list-disc pl-5">
          <li><strong>2012-2016:</strong> Coffey International - Capacity Assessment of the KP Prosecution Service</li>
          <li><strong>2012-2016:</strong> Asia Development Bank - Design and implementation of public perception survey regarding police services</li>
          <li><strong>2015-2018:</strong> European Union Access to Justice Programme - Drafted case management rules for High Court</li>
          <li><strong>2017:</strong> Lahore Development Authority - Drafted Multi-storey Buildings Act</li>
          <li><strong>2018-2019:</strong> Justice Sector Support Program, DFID - Drafted several laws and rules related to legal aid and probation</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold">Major Companies/Federal Government Agencies Represented</h2>
        <ul className="list-disc pl-5">
          <li>DESCON</li>
          <li>Syed Bhais</li>
          <li>Muslim Commercial Bank</li>
          <li>SAMBA Bank</li>
          <li>Darson Group</li>
          <li>Haseeb Waqas Group of Industries</li>
          <li>Naubahar Bottling (Pvt.) Ltd.</li>
          <li>Pakistan Fruit Juices</li>
          <li>Brook Hospital for Animals</li>
          <li>Ovex Technologies</li>
          <li>Crescent Group</li>
          <li>Monnoo Group of Industries</li>
          <li>A.R. Ferguson</li>
          <li>Securities Exchange Commission of Pakistan (SECP)</li>
          <li>Colony Group of Industries</li>
          <li>Zaver Chemicals Limited</li>
          <li>OPI Gas</li>
          <li>Habib Bank Ltd</li>
          <li>Soneri Bank</li>
          <li>Bank of Punjab</li>
          <li>MCB Bank</li>
          <li>National Bank of Pakistan</li>
          <li>SW Sugar Mills</li>
          <li>Escorts Bank</li>
          <li>Sitara Group of Industries</li>
          <li>Elan</li>
          <li>Chakwal Group of Industries</li>
          <li>Kohinoor Textile Mills</li>
        </ul>
      </section>
    </div>
  );
};

export default Ceoprofile;
