// CenteredNav.js
import React from 'react';
import { FaInfoCircle, FaUsers, FaBriefcase, FaNewspaper, FaQuestionCircle } from 'react-icons/fa';

const CenteredNav = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-gray-100 flex justify-center p-4 z-50">
      <ul className="flex space-x-8">
        <li>
          <button onClick={() => scrollToSection('about')} className="text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500">
            <FaInfoCircle />
          </button>
        </li>
        <li>
          <button onClick={() => scrollToSection('team')} className="text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500">
            <FaUsers />
          </button>
        </li>
        <li>
          <button onClick={() => scrollToSection('expertise')} className="text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500">
            <FaBriefcase />
          </button>
        </li>
        <li>
          <button onClick={() => scrollToSection('news')} className="text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500">
            <FaNewspaper />
          </button>
        </li>
        <li>
          <button onClick={() => scrollToSection('faqs')} className="text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500">
            <FaQuestionCircle />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default CenteredNav;